<template lang="pug">
.alert.alert-danger.text-center.mb-0(v-if="getRemainingDays < 1") {{ $t('plan.notifications.expiredAccount.message', { datePaid: getPaymentDate }) }}
  .btn.btn-default.brand-btn-link.font-weight-bold.cursor-pointer(
    v-if="showButton"
    @click="extendPlan()"
  ) {{ $t('plan.notifications.expiredAccount.button') }}

.alert.alert-danger.text-center.mb-0(v-else) {{ $t('plan.notifications.expiredPlan.message', { datePaid: getPaymentDate, remainingDays: getRemainingDays }) }}
  .btn.btn-default.brand-btn-link.font-weight-bold.cursor-pointer(
    v-if="showButton"
    @click="extendPlan()"
  ) {{ $t('plan.notifications.expiredPlan.button') }}
</template>

<script>
  import { createNamespacedHelpers } from 'vuex';
  import dateFormat from '@/mixins/dateFormat';

  const { mapGetters } = createNamespacedHelpers('payment');

  export default {
    mixins: [dateFormat],
    computed: {
      ...mapGetters(['getDatePaidFromExpires', 'getRemainingDaysToAccountExpiration']),
      getPaymentDate() {
        return this.formatDate(this.getDatePaidFromExpires);
      },

      getRemainingDays() {
        return this.getRemainingDaysToAccountExpiration;
      },

      showButton() {
        if (this.$route.name === 'plan_settings' && this.$route.query.extendRecurringPlan) {
          return false;
        }
        if (this.$route.name === 'checkout') {
          return false;
        }
        return true;
      },
    },
    methods: {
      extendPlan() {
        this.$router.replace({
          name: 'plan_settings',
          query: { extendRecurringPlan: true },
        });
      },
    },
  };
</script>
